/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Estaba equivocado, mi portatil si tiene un sensor interno, el problema era que en windows  no me lo detectaba, ahora en linux por el metodo que ya expliqué aquí he conseguido ver la temperatura de mi portatil, para facilitar la tarea XFCE4 tiene un plugin con el que puedes visualizar siempre esta temperatura, se llama xfce4-sensors-plugin."), "\n", React.createElement(_components.p, null, "De esta forma ahora conozco los limites de temperatura de mi portatil, en reposo suele estar por debajo de los 50 ºC, pero cuando lo hago trabajar llega hasta los 77 ºC y luego se cuelga."), "\n", React.createElement(_components.p, null, "Las especificaciones de la base cambian, y ahora no hace falta un sensor de temperatura, se enviará la temperatura a través, del USB con la aplicación que tendré que programar y usando la libreria libsensors4 para obtener esta temperatura."), "\n", React.createElement(_components.p, null, "Es algo que aun tengo que estudiar bastante, espero conseguir algo aceptable."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
